<template>
  <div
    class="wrapper"
    :style="`--blur-value: ${density}px; min-height: ${minHeight}`"
  >
    <slot />
    <div
      v-if="isBlurred"
      class="blur-layer"
    >
      <v-alert
        v-if="$slots.info"
        type="info"
        :prominent="$vuetify.breakpoint.mdAndUp"
        elevation="20"
        class="centered-alert"
      >
        <div class="px-md-6 px-2">
          <slot name="info" />
        </div>
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBlurred: {
      type: Boolean,
      default: true
    },
    density: {
      type: Number,
      default: 3
    },
    minHeight: {
      type: String,
      default: '150px'
    }
  }
}
</script>

<style scoped>
.wrapper {
  position: relative;
}

.blur-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(var(--blur-value));
}

.centered-alert {
  /* Adjust these values as needed */
  width: 50%;
  padding: 20px;
}

@media (max-width: 600px) {
  .centered-alert {
    width: 90%;
  }
}
</style>
