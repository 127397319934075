<template>
  <v-alert
    type="info"
    class="mb-0"
    outlined
    text
  >
    <v-row align="center">
      <v-col class="grow">
        <div class="error-text">
          {{ $t('topics.list.errors.max-active-topics.0', { maxActiveTopics }) }}<br>
          {{ $t('topics.list.errors.max-active-topics.1') }}
          <router-link :to="{ name: 'Settings', params: { section: 'contract' } }">
            {{ $t('topics.list.errors.max-active-topics.2') }}
          </router-link>
        </div>
      </v-col>
      <v-col class="shrink">
        <v-btn
          icon
          class="info--text"
          @click="dismiss"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  props: {
    maxActiveTopics: {
      type: Number,
      required: true
    }
  },

  methods: {
    dismiss () {
      this.$emit('dismiss')
    }
  }
}
</script>
